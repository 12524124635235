import { EclipseContainer } from './container';
import { IEclipseConfig, IEventInfo } from './interface/injection.interface';

export class InjectionV2 extends EclipseContainer {
  constructor(eclipseConfig: IEclipseConfig, event: IEventInfo) {
    super(eclipseConfig);
    this.setIframeInjection(eclipseConfig, event);
  }

  initV2(config : IEclipseConfig, event: IEventInfo) {
    if (event?.data?.sessionStorageData) {
      for (let key in event.data.sessionStorageData) {
        sessionStorage.setItem(key, event.data.sessionStorageData[key]);
      }
    }
    const div = document.createElement('div');
    div.id = 'pms';
    div.style.width = '100vw';
    div.style.height = '100%';
    const pmsInjection = document.createElement('pms-injectable');
    div.prepend(pmsInjection);
    const main = document.querySelector('main');
    main?.prepend(div);
    window.pid = config.partnerId;
    const jsElm = document.createElement('script');
    jsElm.type = 'application/javascript';
    jsElm.src = this.v2ScriptUrl;
    jsElm.setAttribute('pid', window.pid);
    jsElm.setAttribute('mode', this.v2Mode);
    document.body.prepend(jsElm);
    if (!window._PMS) window._PMS = {};
    if (!window._PMS.cbFunctions) window._PMS.cbFunctions = {};
    window._PMS.pid = config.partnerId;
    window._PMS.cbFunctions['projectType'] = function () {
      return ['solar', 'battery', 'heatPumps']
    };
    sessionStorage.setItem('tempPartner', config.partnerId);
    setTimeout(() => {
      window._PMS.reInitWithSession();
    }, 2000);
  }
  setIframeInjection(config : IEclipseConfig, event: IEventInfo) {
    this.initV2(config , event);
    setTimeout(() => {
      const iframe = window.document.querySelector('#pms-frame') as any;
      this.containerWindow = iframe.contentWindow;
      this.setComm();
    }, 3000);
  }
}
