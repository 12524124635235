export const environment = {
  ENV_MODE: 'develop',
  SHADOW: 'false',
  ENDPOINT_V1: 'https://engine-k8s-test.solar.com/symfony/api/',
  ENDPOINT_V2: 'https://engine-k8s-test.solar.com/symfony/v2/',
  ENDPOINT_V21: 'https://engine-k8s-test.solar.com/symfony/v21/',
  ENDPOINT_INC: 'https://engine-k8s-test.solar.com/',
  ENDPOINT_INJ: 'https://engine-k8s-test.solar.com/injection/',
  CDN_URL: 'https://assets.solar.com',
  MAPS_API_KEY: 'AIzaSyAAcvldG0koZjnEalZR38hrMHZXIyWPGjA',
  SOURCE_FILES: 'https://test-source.solar.com/',
  DOWNTIME_FILES: 'https://downtime-interface.solar.com/',
  UTILITY_URL: 'https://utilityapi.com/authorize/pickmysolar?demo',
  ENDPOINT_ANALYTICS: 'https://engine-k8s-test.solar.com/project/api/',
};